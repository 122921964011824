<template>
  <div id="lotus">
    <myHeader></myHeader>
    <div class="backBox">
      <div class="titleLeft">
        <div class="back_nav" @click="goBack()"></div>
      </div>
    </div>
    <div class="bigBox">
      <div class="topBox">
        <div class="t_one_box">
          <div class="sTitleBox">
            <img src="../../assets/detail/timg1.png" style="margin-right: 10px">
            <div>{{ '实时监测' }}</div>
          </div>
          <div class="t_one_box_B_box">
            <div class="t_one_box_L">
              <div class="oneBox">
                <div class="imgBox">
                  <img src="../../assets/detail/simg18.png">
                </div>
                <div class="c_box">
                  <div class="label">{{ '正常' + '(台)' }}</div>
                  <div class="value">{{ normalCount }}</div>
                </div>
              </div>
              <div class="oneBox">
                <div class="imgBox">
                  <img src="../../assets/detail/simg19.png">
                </div>
                <div class="c_box">
                  <div class="label">{{ '报警' + '(台)' }}</div>
                  <div class="value">{{ policeCount }}</div>
                </div>
              </div>
              <div class="oneBox">
                <div class="imgBox">
                  <img src="../../assets/detail/simg20.png">
                </div>
                <div class="c_box">
                  <div class="label">{{ '故障' + '(台)' }}</div>
                  <div class="value">{{ faultCount }}</div>
                </div>
              </div>
            </div>
            <div class="t_one_box_C">
              <div id="chart1" style="width: 100%; height: 100%;"></div>
            </div>
            <div class="t_one_box_R">
              <div class="legnedBox">
                <div class="colorBox" v-for="(item,index) in colorList" :key="index">
                  <div class="color">
                    <div class="c" :style="{'background-color': item.color}"></div>
                    <div class="c_label">{{ item.label }}</div>
                  </div>
                  <div>{{ item.percent + '%' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="t_two_box">
          <div class="sTitleBox">
            <img src="../../assets/detail/timg1.png" style="margin-right: 10px">
            <div>{{ '报警响应率' }}</div>
          </div>
          <div class="t_two_box_B_box">
            <div class="t_t_b_L">
              <div style="font-size: 32px;color: #f0c025;">{{ policePercent + '%' }}</div>
              <div>
                <img src="../../assets/detail/sbgc4.png">
              </div>
              <div>{{ '报警处理率' }}</div>
            </div>
            <div class="t_t_b_R">
              <div style="font-size: 32px;color: #ec4c3b;">{{ faultPercent + '%' }}</div>
              <div>
                <img src="../../assets/detail/sbgc4.png">
              </div>
              <div>{{ '故障处理率' }}</div>
            </div>
          </div>
        </div>
        <div class="t_three_box">
          <div class="sTitleBox">
            <img src="../../assets/detail/timg1.png" style="margin-right: 10px">
            <div>{{ '当前告警' }}</div>
          </div>
          <div class="tables">
            <el-table class="table" :data="tableData" height="200">
              <el-table-column align="center" prop="zt" label="状态">
                <template slot-scope="scope">
                  <span style="color:#923232" v-if="scope.row.type=='1'">{{ '报警' }}</span>
                  <span style="color:#c39d26" v-if="scope.row.type=='2'">{{ '故障' }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="content" label="告警信息">
              </el-table-column>
              <el-table-column align="center" prop="alarmTime" label="时间">
              </el-table-column>
<!--              <el-table-column align="center" prop="zxt" label="子系统">-->
<!--              </el-table-column>-->
            </el-table>
          </div>
        </div>
      </div>
      <div class="centerBox">
        <div class="c_one_box">
          <div class="sTitleBox">
            <img src="../../assets/detail/timg1.png" style="margin-right: 10px">
            <div>{{ '用电定额' }}</div>
          </div>
          <div class="c_one_b_box">
            <div class="c_one_b_box_L">
              <div id="chart2" style="width: 100%;height: 100%;"></div>
            </div>
            <div class="c_one_b_box_R">
              <div class="s_legnedBox">
                <div class="bgcBox">
                  <div class="label">{{ '今日电耗(kWh)' }}</div>
                  <div class="value">{{ totalEnergy }}</div>
                </div>
                <div class="bgcBox">
                  <div class="label">{{ '今日定额(kWh)' }}</div>
                  <div class="value">{{ energyQuota }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c_two_box">
          <div class="sTitleBox">
            <img src="../../assets/detail/timg1.png" style="margin-right: 10px">
            <div>{{ '分项用电' }}</div>
          </div>
          <div class="c_two_b_box">
            <div class="c_t_box_L">
              <div id="chart3" style="width: 100%;height: 100%;"></div>
            </div>
            <div class="c_t_box_R">
              <div class="legnedBox">
                <div class="colorBox" v-for="(item,index) in colorList2" :key="index">
                  <div class="color">
                    <div class="c" :style="{'background-color': item.color}"></div>
                    <div class="c_label">{{ item.label }}</div>
                  </div>
                  <div>{{ item.percent + '%' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c_three_box">
          <div class="sTitleBox">
            <img src="../../assets/detail/timg1.png" style="margin-right: 10px">
            <div>{{ '综合态势' }}</div>
          </div>
          <div class="c_t_t_b_box">
            <div class="c_t_t_b_L">
              <div style="position:relative;">
                <img src="../../assets/detail/sbgc5.png">
                <div
                    style="position: absolute; top: 50%;left: 50%;transform: translate(-50%,-50%);width: 100%;text-align: center;font-size: 0.24rem">
                  {{energyEfficiencyGrade+'级'}}
                </div>
              </div>
              <div>{{ '综合能效等级' }}</div>
            </div>
            <div class="c_t_t_b_R">
              <div style="position:relative;">
                <img src="../../assets/detail/sbgc6.png">
                <div
                    style="position: absolute; top: 50%;left: 50%;transform: translate(-50%,-50%);width: 100%;text-align: center;font-size: 0.24rem">
                  {{ safetyScore + '分' }}
                </div>
              </div>
              <div>{{ '安全管理评分' }}</div>
            </div>
          </div>
        </div>
        <div class="c_four_box">
          <div class="sTitleBox">
            <img src="../../assets/detail/timg1.png" style="margin-right: 10px">
            <div>{{ '设备列表' }}</div>
          </div>
          <div class="c_f_b_box">
            <div class="deviceBgc">
              <div class="imgBox">
                <img src="../../assets/detail/simg21.png">
              </div>
              <div class="contentBox">
                <div class="labelBox">
                  <div class="labelName">{{ '充电桩' }}</div>
                  <div class="label">{{ chargingStation.count + '个' }}</div>
                </div>
                <div class="valueBox">
                  <div class="valueName">{{ '功率:' }}</div>
                  <div class="value">{{ chargingStation.ratedPower + 'kw' }}</div>
                </div>
              </div>
            </div>
            <div class="deviceBgc">
              <div class="imgBox">
                <img src="../../assets/detail/simg22.png">
              </div>
              <div class="contentBox">
                <div class="labelBox">
                  <div class="labelName">{{ '空调' }}</div>
                  <div class="label">{{ air.count + '台' }}</div>
                </div>
                <div class="valueBox">
                  <div class="valueName">{{ '功率:' }}</div>
                  <div class="value">{{ air.ratedPower + 'kw' }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="c_f_b_box">
            <div class="deviceBgc">
              <div class="imgBox">
                <img src="../../assets/detail/simg23.png">
              </div>
              <div class="contentBox">
                <div class="labelBox">
                  <div class="labelName">{{ '空压机' }}</div>
                  <div class="label">{{ airCompressor.count + '台' }}</div>
                </div>
                <div class="valueBox">
                  <div class="valueName">{{ '功率:' }}</div>
                  <div class="value">{{ airCompressor.ratedPower + 'kw' }}</div>
                </div>
              </div>
            </div>
            <div class="deviceBgc">
              <div class="imgBox">
                <img src="../../assets/detail/simg24.png">
              </div>
              <div class="contentBox">
                <div class="labelBox">
                  <div class="labelName">{{ '电动机' }}</div>
                  <div class="label">{{ motor.count + '台' }}</div>
                </div>
                <div class="valueBox">
                  <div class="valueName">{{ '功率:' }}</div>
                  <div class="value">{{ motor.ratedPower + 'kw' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottomBox">
        <div class="b_one_box">
          <div class="titleBox">
            <div class="sTitleBox">
              <img src="../../assets/detail/timg1.png" style="margin-right: 10px">
              <div>{{ '今日用能' }}</div>
            </div>
            <div class="tabBox">
              <div :class="[tabNum==index?'tabItemClick':'tabItem']" v-for="(item,index) in tabList" :key="index"
                   @click="getTab(index)">{{ item.label }}
              </div>
            </div>
          </div>
          <div class="ttBox">
            <div class="tt_bgc">
              <div class="tt_bgc_L">
                <div style="margin-right: 0.1rem">
                  <img src="../../assets/detail/simg25.png">
                </div>
                <div>{{ '用电量(kWh)' }}</div>
              </div>
              <div class="tt_bgc_R">{{ energy }}</div>
            </div>
            <div class="tt_bgc">
              <div class="tt_bgc_L">
                <div style="margin-right: 0.1rem">
                  <img src="../../assets/detail/simg26.png">
                </div>
                <div>{{ '碳排放量(kg)' }}</div>
              </div>
              <div class="tt_bgc_R">{{ carbonEmission }}</div>
            </div>
          </div>
          <div style="height: 74%;width: 100%;position: relative;">
            <!--            <div class="jfpg_box">-->
            <!--              <div class="colorBox" v-for="(item,index) in colorList3" :key="index">-->
            <!--                <div class="color" :style="{'background-color': item.color}"></div>-->
            <!--                <div style="font-size: 0.12rem;">{{item.label}}</div>-->
            <!--              </div>-->
            <!--            </div>-->
            <div id="chart4" style="width: 100%;height: 100%;" v-show="showChart6"></div>
            <div id="chart6" style="width: 100%;height: 100%;" v-show="!showChart6"></div>
          </div>
        </div>
        <div class="b_two_box">
          <div class="sTitleBox">
            <img src="../../assets/detail/timg1.png" style="margin-right: 10px">
            <div>{{ '子系统运行情况' }}</div>
          </div>
          <div class="ttBox">
            <div class="tt_bgc">
              <div class="tt_bgc_L">
                <div style="margin-right: 0.1rem">
                  <img src="../../assets/detail/simg27.png">
                </div>
                <div>{{ '监控点位' }}</div>
              </div>
              <div class="tt_bgc_R">{{ point }}</div>
            </div>
            <div class="tt_bgc">
              <div class="tt_bgc_L">
                <div style="margin-right: 0.1rem">
                  <img src="../../assets/detail/simg28.png">
                </div>
                <div>{{ '消防安全' }}</div>
              </div>
              <div class="tt_bgc_R">{{ fireSafety }}</div>
            </div>
          </div>
          <div style="height: 74%;width: 100%;">
            <div id="chart5" style="width: 100%;height: 100%;"></div>
          </div>
        </div>
      </div>
    </div>
    <HomeFooter></HomeFooter>
  </div>
</template>

<script>

import myHeader from "@/components/homeHeader.vue";
import HomeFooter from "@/components/footer.vue";
import {lotusApi} from '@/api/lotusDetail'
import * as echarts from "echarts";

export default {
  components: {myHeader, HomeFooter},
  name: "lotus",
  data() {
    return {
      stationId: '',
      tabNum: '0',
      //正常数量
      normalCount: '',
      //报警数量
      policeCount: '',
      //故障数量
      faultCount: '',
      //报警处理率
      policePercent: '',
      //故障处理率
      faultPercent: '',
      //今日定额
      energyQuota: '',
      //今日电耗
      totalEnergy: '',
      //能效
      energyEfficiencyGrade:'',
      //评分
      safetyScore: '',
      //充电桩
      chargingStation: {},
      //空调
      air: {},
      //空压机
      airCompressor: {},
      //电动机
      motor: {},
      //点位
      point: '',
      //消防安全
      fireSafety: '',
      //碳排
      carbonEmission: '',
      //用电量
      energy: '',
      //tab显示
      showChart6: true,
      tabList: [
        {label: '用电量', id: '0'},
        {label: '碳排放量', id: '1'}
      ],
      colorList: [
        {label: '正常', percent: '0', alarmStatus: '0', color: '#2ee1b2'},
        {label: '报警', percent: '0', alarmStatus: '1', color: '#f0c025'},
        {label: '故障', percent: '0', alarmStatus: '2', color: '#ec4c3b'},
        {label: '离线', percent: '0', alarmStatus: '3', color: '#0e49bb'},
      ],
      colorList2: [
        {loadeqId: '1',label: '充电桩', percent: '0', color: '#2ee1b2'},
        {loadeqId: '2',label: '空调', percent: '0', color: '#18bfe9'},
        {loadeqId: '3',label: '空压机', percent: '0', color: '#3775ee'},
        {loadeqId: '4',label: '电动机', percent: '0', color: '#0e49bb'},
        {loadeqId: '5',label: '照明', percent: '0', color: '#f0c025'},
        {loadeqId: '6',label: '电梯', percent: '0', color: '#ec4c3b'},
        {loadeqId: '7',label: '展厅设备', percent: '0', color: '#e552ff'},
        {loadeqId: '99',label: '其他', percent: '0', color: '#be0000'},
      ],
      colorList3: [
        {label: '尖', value: '0', color: 'rgba(225,66,66,0.3)'},
        {label: '峰', value: '1', color: 'rgba(225,199,75,0.3)'},
        {label: '平', value: '2', color: 'rgba(61,226,188,0.3)'},
        {label: '谷', value: '3', color: 'rgba(58,109,212,0.3)'},
      ],
      tableData: [],
    }
  },
  created() {
    this.stationId = sessionStorage.getItem('stationId')
    this.getCountByAlarmStatus()
    this.getProcessingRate()
    this.getTopList()
    this.getTotalWithQuota()
    this.getByType()
    this.getByStationId()
    this.getCountImportants()
    this.getListAndTypeTotal()
    this.getByUsageType()
  },
  mounted() {
  },
  methods: {
    goBack(){
      this.$router.go(-1);
    },
    getTab(index) {
      this.tabNum = index
      if (this.tabNum == '0') {
        this.showChart6 = true
        this.getByUsageType()
      }else if (this.tabNum == '1') {
        this.showChart6 = false
        this.getByUsageType()
      }
    },
    getChart1(data) {
      var myChart = echarts.init(document.getElementById('chart1'), 'shine');
      let normalValue = ''
      let policeValue = ''
      let faultValue = ''
      let offlineValue = ''
      data.forEach(item => {
        if (item.alarmStatus == '0')  {
          normalValue = Number(item.count)
        }else if(item.alarmStatus == '1') {
          policeValue = Number(item.count)
        }else if(item.alarmStatus == '2') {
          faultValue = Number(item.count)
        }else if(item.alarmStatus == '3') {
          offlineValue = Number(item.count)
        }
      })
      let all = normalValue + policeValue + faultValue + offlineValue
      var option = {
        tooltip: {
          trigger: 'item'
        },
        graphic: [
          {
            type: 'text',
            left: 'center',
            top: 'center',
            style: {
              text: `${all}\n台`,
              textAlign: 'center',
              fill: '#fff',
              fontSize: 20,
              lineHeight: 20
            }
          }
        ],
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['70%', '90%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: 40,
            //     fontWeight: 'bold'
            //   }
            // },
            labelLine: {
              show: false
            },
            data: [
              {value: normalValue, name: '正常',itemStyle: {color: '#2ee1b2'}},
              {value: policeValue, name: '报警',itemStyle: {color: '#f0c025'}},
              {value: faultValue, name: '故障',itemStyle: {color: '#ec4c3b'}},
              {value: offlineValue, name: '离线',itemStyle: {color: '#0e49bb'}},
            ]
          }
        ]
      };
      myChart.setOption(option, true);
    },
    getChart2(data) {
      var myChart = echarts.init(document.getElementById('chart2'), 'shine');
      var option = {
        series: [
          {
            type: 'gauge',
            axisLine: {
              lineStyle: {
                width: 10,
                color: [
                  [0.3, '#67e0e3'],
                  [0.7, '#37a2da'],
                  [1, '#fd666d']
                ]
              }
            },
            pointer: {
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              distance: -10,
              length: 8,
              lineStyle: {
                color: '#fff',
                width: 2
              }
            },
            splitLine: {
              distance: -5,
              length: 0,
              lineStyle: {
                color: '#fff',
                width: 4
              }
            },
            axisLabel: {
              color: 'inherit',
              distance: 20,
              fontSize: 10
            },
            detail: {
              valueAnimation: true,
              formatter: '{value} %',
              color: 'inherit',
              fontSize: '16'
            },
            data: [
              {
                value: data
              }
            ]
          }
        ]
      };
      myChart.setOption(option, true);
    },
    getChart3(data) {
      var myChart = echarts.init(document.getElementById('chart3'), 'shine');
      let total = Number(data['1'].energy) + Number(data['2'].energy) + Number(data['3'].energy) + Number(data['4'].energy) + Number(data['5'].energy) + Number(data['6'].energy) + Number(data['7'].energy) + Number(data['99'].energy)
      var option = {
        tooltip: {
          trigger: 'item'
        },
        graphic: [
          {
            type: 'text',
            left: 'center',
            top: 'center',
            style: {
              text: `${total.toFixed(2)}\n今日累计能耗\n(kWh)`,
              textAlign: 'center',
              fill: '#fff',
              fontSize: 14,
              lineHeight: 14
            }
          }
        ],
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['70%', '90%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: 40,
            //     fontWeight: 'bold'
            //   }
            // },
            labelLine: {
              show: false
            },
            data: [
              {value: data['1'].energy, name: '充电桩',itemStyle:{color:'#2ee1b2'}},
              {value: data['2'].energy, name: '空调',itemStyle:{color:'#18bfe9'}},
              {value: data['3'].energy, name: '空压机',itemStyle:{color:'#3775ee'}},
              {value: data['4'].energy, name: '电动机',itemStyle:{color:'#0e49bb'}},
              {value: data['5'].energy, name: '照明',itemStyle:{color:'#f0c025'}},
              {value: data['6'].energy, name: '电梯',itemStyle:{color:'#ec4c3b'}},
              {value: data['7'].energy, name: '展厅设备',itemStyle:{color:'#e552ff'}},
              {value: data['99'].energy, name: '其他',itemStyle:{color:'#be0000'}},
            ]
          }
        ]
      };
      myChart.setOption(option, true);
    },
    getChart4(data) {
      var myChart = echarts.init(document.getElementById('chart4'), 'shine');
      let colorList = ['rgba(58, 109, 212, 0.3)', 'rgba(58, 109, 212, 0.3)', 'rgba(61, 226, 188 , 0.3)', 'rgba(255, 199, 75 , 0.3)', 'rgba(255, 66, 66 , 0.3)'];
      let area = data.timePeriods
      let arr1 = []
      let arr2 = []
      let arr3 = []
      let arr99 = []
      data.statHourly['1'].forEach((item) => {
        arr1.push(item.energy)
      })
      data.statHourly['2'].forEach((item) => {
        arr2.push(item.energy)
      })
      data.statHourly['3'].forEach((item) => {
        arr3.push(item.energy)
      })
      data.statHourly['99'].forEach((item) => {
        arr99.push(item.energy)
      })
      var option = {
        title: {
          text: '电量: kWh    {j|} 尖  {f|} 峰  {p|} 平  {g|} 谷',
          textStyle: {
            color: '#ffffff',
            fontSize: '12',
            rich: {
              'j': {
                backgroundColor: 'rgba(225,66,66,.3)',
                width: 20
              },
              'f': {
                backgroundColor: 'rgba(225,199,75,.3)',
                width: 20
              },
              'p': {
                backgroundColor: 'rgba(61,226,188,.3)',
                width: 20
              },
              'g': {
                backgroundColor: 'rgba(58,109,212,.3)',
                width: 20
              }
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          top: '0',
          right: '4%',
          textStyle: {
            color: '#ffffff'
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            axisLabel: {
              color: 'white'
            },
            axisLine: {
              lineStyle: {
                color: '#1b3c74'
              }
            },
            type: 'category',
            data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              color: 'white',
            },
            axisLine: {
              lineStyle: {
                color: '#1b3c74'
              }
            },
            splitLine: {
              lineStyle: {
                color: '#1b3c74',
                type: 'dashed'
              }
            }
          }
        ],
        series: [
          {
            name: '生产',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            data: arr1,
            markArea: {
              data: [
                [
                  {
                    xAxis: '1',
                    itemStyle: {
                      color: colorList[area[0].periodType]
                    },
                  },
                  {
                    xAxis: '1'
                  }
                ],
                [
                  {
                    xAxis: '2',
                    itemStyle: {
                      color: colorList[area[1].periodType]
                    },
                  },
                  {
                    xAxis: '2'
                  }
                ], [
                  {
                    xAxis: '3',
                    itemStyle: {
                      color: colorList[area[2].periodType]
                    },
                  },
                  {
                    xAxis: '3'
                  }
                ], [
                  {
                    xAxis: '4',
                    itemStyle: {
                      color: colorList[area[3].periodType]
                    },
                  },
                  {
                    xAxis: '4'
                  }
                ], [
                  {
                    xAxis: '5',
                    itemStyle: {
                      color: colorList[area[4].periodType]
                    },
                  },
                  {
                    xAxis: '5'
                  }
                ], [
                  {
                    xAxis: '6',
                    itemStyle: {
                      color: colorList[area[5].periodType]
                    },
                  },
                  {
                    xAxis: '6'
                  }
                ], [
                  {
                    xAxis: '7',
                    itemStyle: {
                      color: colorList[area[6].periodType]
                    },
                  },
                  {
                    xAxis: '7'
                  }
                ], [
                  {
                    xAxis: '8',
                    itemStyle: {
                      color: colorList[area[7].periodType]
                    },
                  },
                  {
                    xAxis: '8'
                  }
                ], [
                  {
                    xAxis: '9',
                    itemStyle: {
                      color: colorList[area[8].periodType]
                    },
                  },
                  {
                    xAxis: '9'
                  }
                ], [
                  {
                    xAxis: '10',
                    itemStyle: {
                      color: colorList[area[9].periodType]
                    },
                  },
                  {
                    xAxis: '10'
                  }
                ], [
                  {
                    xAxis: '11',
                    itemStyle: {
                      color: colorList[area[10].periodType]
                    },
                  },
                  {
                    xAxis: '11'
                  }
                ], [
                  {
                    xAxis: '12',
                    itemStyle: {
                      color: colorList[area[11].periodType]
                    },
                  },
                  {
                    xAxis: '12'
                  }
                ],
                [
                  {
                    xAxis: '13',
                    itemStyle: {
                      color: colorList[area[12].periodType]
                    },
                  },
                  {
                    xAxis: '13'
                  }
                ],[
                  {
                    xAxis: '14',
                    itemStyle: {
                      color: colorList[area[13].periodType]
                    },
                  },
                  {
                    xAxis: '14'
                  }
                ],[
                  {
                    xAxis: '15',
                    itemStyle: {
                      color: colorList[area[14].periodType]
                    },
                  },
                  {
                    xAxis: '15'
                  }
                ],[
                  {
                    xAxis: '16',
                    itemStyle: {
                      color: colorList[area[15].periodType]
                    },
                  },
                  {
                    xAxis: '16'
                  }
                ],[
                  {
                    xAxis: '17',
                    itemStyle: {
                      color: colorList[area[16].periodType]
                    },
                  },
                  {
                    xAxis: '17'
                  }
                ],[
                  {
                    xAxis: '18',
                    itemStyle: {
                      color: colorList[area[17].periodType]
                    },
                  },
                  {
                    xAxis: '18'
                  }
                ],[
                  {
                    xAxis: '19',
                    itemStyle: {
                      color: colorList[area[18].periodType]
                    },
                  },
                  {
                    xAxis: '19'
                  }
                ],[
                  {
                    xAxis: '20',
                    itemStyle: {
                      color: colorList[area[19].periodType]
                    },
                  },
                  {
                    xAxis: '20'
                  }
                ],[
                  {
                    xAxis: '21',
                    itemStyle: {
                      color: colorList[area[20].periodType]
                    },
                  },
                  {
                    xAxis: '21'
                  }
                ],[
                  {
                    xAxis: '22',
                    itemStyle: {
                      color: colorList[area[21].periodType]
                    },
                  },
                  {
                    xAxis: '22'
                  }
                ],[
                  {
                    xAxis: '23',
                    itemStyle: {
                      color: colorList[area[22].periodType]
                    },
                  },
                  {
                    xAxis: '23'
                  }
                ],[
                  {
                    xAxis: '24',
                    itemStyle: {
                      color: colorList[area[23].periodType]
                    },
                  },
                  {
                    xAxis: '24'
                  }
                ],
              ]
            }

          },
          {
            name: '办公',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            data: arr2
          },
          {
            name: '生活',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            data: arr3
          },
          {
            name: '其他',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            data: arr99
          },
        ]
      };
      myChart.setOption(option, true);
    },
    getChart6(data) {
      var myChart = echarts.init(document.getElementById('chart6'), 'shine');
      let colorList = ['rgba(58, 109, 212, 0.3)', 'rgba(58, 109, 212, 0.3)', 'rgba(61, 226, 188 , 0.3)', 'rgba(255, 199, 75 , 0.3)', 'rgba(255, 66, 66 , 0.3)'];
      let area = data.timePeriods
      let arr1 = []
      let arr2 = []
      let arr3 = []
      let arr99 = []
      data.statHourly['1'].forEach((item) => {
        arr1.push(item.carbonEmission)
      })
      data.statHourly['2'].forEach((item) => {
        arr2.push(item.carbonEmission)
      })
      data.statHourly['3'].forEach((item) => {
        arr3.push(item.carbonEmission)
      })
      data.statHourly['99'].forEach((item) => {
        arr99.push(item.carbonEmission)
      })
      var option = {
        title: {
          text: '碳排放量: kg',
          textStyle: {
            color: '#ffffff',
            fontSize: '12',
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          top: '0',
          right: '4%',
          textStyle: {
            color: '#ffffff'
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            axisLabel: {
              color: 'white'
            },
            axisLine: {
              lineStyle: {
                color: '#1b3c74'
              }
            },
            type: 'category',
            data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              color: 'white',
            },
            axisLine: {
              lineStyle: {
                color: '#1b3c74'
              }
            },
            splitLine: {
              lineStyle: {
                color: '#1b3c74',
                type: 'dashed'
              }
            }
          }
        ],
        series: [
          {
            name: '生产',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            data: arr1,
          },
          {
            name: '办公',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            data: arr2
          },
          {
            name: '生活',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            data: arr3
          },
          {
            name: '其他',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            data: arr99
          },
        ]
      };
      myChart.setOption(option, true);
    },
    getChart5(data) {
      var myChart = echarts.init(document.getElementById('chart5'), 'shine');
      let xArr = []
      let arrN = []
      let arrW = []
      let arrF = []
      data.forEach(item => {
        xArr.push(item.name)
        arrN.push(item.normalAmount)
        arrW.push(item.warningAmount)
        arrF.push(item.faultAmount)
      })
      var option = {
        title: {
          text: '子系统名称',
          textStyle: {
            color: '#ffffff',
            fontSize: '16'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {
          top: '0',
          right: '4%',
          textStyle: {
            color: '#ffffff'
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            color: 'white'
          },
          axisLine: {
            lineStyle: {
              color: '#1b3c74'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#1b3c74',
              type: 'dashed'
            }
          }
        },
        yAxis: {
          type: 'category',
          data: xArr,
          axisLabel: {
            color: 'white',
          },
          axisLine: {
            lineStyle: {
              color: '#1b3c74'
            }
          },

        },
        series: [
          {
            name: '正常',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              // 设置柱体颜色
              color: '#2ee1b2'
            },
            data: arrN
          },
          {
            name: '报警',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              // 设置柱体颜色
              color: '#f0c025'
            },
            data: arrW
          },
          {
            name: '故障',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              // 设置柱体颜色
              color: '#ec4c3b'
            },
            data: arrF
          },
        ]
      };
      myChart.setOption(option, true);
    },
    //实时监测
    getCountByAlarmStatus() {
      const param = {
        stationId: this.stationId
      }
      lotusApi.countByAlarmStatus(param).then(res => {
        if (res.code == 200) {
          let arr = Object.values(res.data)
          arr.forEach(item => {
            if (item.alarmStatus == 0) {
              this.normalCount = item.count;
              this.$set(this.colorList[0], 'percent', item.percent)
            } else if (item.alarmStatus == 1) {
              this.policeCount = item.count;
              this.$set(this.colorList[1], 'percent', item.percent)
            } else if (item.alarmStatus == 2) {
              this.faultCount = item.count;
              this.$set(this.colorList[2], 'percent', item.percent)
            } else if (item.alarmStatus == 3) {
              this.$set(this.colorList[3], 'percent', item.percent)
            }
          })
          this.getChart1(arr)
        }
      })
    },
    //报警响应率
    getProcessingRate() {
      const param = {
        stationId: this.stationId
      }
      lotusApi.processingRate(param).then(res => {
        if (res.code == 200){
          this.policePercent = res.data['1']
          this.faultPercent = res.data['2']
        }
      })
    },
    //当前告警
    getTopList() {
      const param = {
        stationId: this.stationId
      }
      lotusApi.toplist(param).then(res => {
        if (res.code == 200){
          this.tableData = res.data
        }
      })
    },
    //用电定额
    getTotalWithQuota(){
      const param = {
        stationId: this.stationId
      }
      lotusApi.totalWithQuota(param).then(res => {
        if (res.code == 200){
          this.totalEnergy = Number(res.data.energy / 1000).toFixed(2)
          this.energyQuota = Number(res.data.energyQuota / 1000).toFixed(2)
          this.getChart2(Number(res.data.percent).toFixed(2))
        }
      })
    },
    //分项用电
    getByType(){
      const param = {
        stationId: this.stationId
      }
      lotusApi.byType(param).then(res => {
        if (res.code == 200){
          let arr = Object.values(res.data)
          this.$set(this.colorList2[0], 'percent', res.data['1'].percent)
          this.$set(this.colorList2[1], 'percent', res.data['2'].percent)
          this.$set(this.colorList2[2], 'percent', res.data['3'].percent)
          this.$set(this.colorList2[3], 'percent', res.data['4'].percent)
          this.$set(this.colorList2[4], 'percent', res.data['5'].percent)
          this.$set(this.colorList2[5], 'percent', res.data['6'].percent)
          this.$set(this.colorList2[6], 'percent', res.data['7'].percent)
          this.$set(this.colorList2[7], 'percent', res.data['99'].percent)
          this.getChart3(res.data)
        }
      })
    },
    //综合态势
    getByStationId() {
      const param = {
        stationId: this.stationId
      }
      lotusApi.byStationId(param).then(res => {
        this.energyEfficiencyGrade = res.data.energyEfficiencyGrade
        this.safetyScore = res.data.safetyScore
      })
    },
    //设备列表
    getCountImportants(){
      const param = {
        stationId: this.stationId
      }
      lotusApi.countImportants(param).then(res => {
        if (res.code == 200) {
          //充电桩
          this.chargingStation = res.data['1']
          //空调
          this.air = res.data['2']
          //空压机
          this.airCompressor = res.data['3']
          //电动机
          this.motor = res.data['4']

        }
      })
    },
    //子系统运行情况
    getListAndTypeTotal() {
      const param = {
        stationId: this.stationId
      }
      lotusApi.listAndTypeTotal(param).then(res => {
        if (res.code == '200'){
          this.point = res.data.typeTotals['1']
          this.fireSafety = res.data.typeTotals['2']
          this.getChart5(res.data.subsystems)
        }
      })
    },
    //今日用能
    getByUsageType() {
      const param = {
        stationId: this.stationId
      }
      lotusApi.byUsageType(param).then(res => {
        if (res.code == '200'){
          this.carbonEmission = Number(res.data.total.carbonEmission / 1000).toFixed(2)
          this.energy = Number(res.data.total.energy).toFixed(2)
          if (this.tabNum == '0'){
            this.getChart4(res.data)
          }else if(this.tabNum == '1'){
            this.getChart6(res.data)
          }
        }
      })
    }
  }
}

</script>
<style src="./ad.css" scoped></style>
<style scoped>
.back_nav {
  width: 0.8rem;
  height: 0.34rem;
  background: url('../../assets/images/monitoring/back_nav.png') no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}
.backBox {
  width: 100%;
  height: 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleLeft {
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.tables >>> .el-table:not(.reportTable) {
  background-color: #03173c;
  border: 1px solid #132b53;
}

#lotus {
  height: 100vh;
  background-color: #071024;
  font-size: 0.16rem;
  box-sizing: border-box;
}

.bigBox {
  //height: 100%;
  padding: 0 0.2rem 0.2rem 0.2rem;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  background-color: #071024;
}

.bigBox .topBox {
  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.bigBox .topBox .t_one_box {
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  flex: 32.8%;
  max-width: 32.8%;
  height: 100%;
  background: url(../../assets/detail/sbgc10.png) no-repeat;
  background-size: 100% 100%;

}

.bigBox .topBox .t_one_box .t_one_box_B_box {
  height: 80%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.bigBox .topBox .t_one_box .t_one_box_B_box .t_one_box_L {
  max-width: 20%;
  flex: 20%;
  height: 80%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}

.bigBox .topBox .t_one_box .t_one_box_B_box .t_one_box_C {
  max-width: 50%;
  flex: 50%;
  height: 100%;
  box-sizing: border-box;
}

.bigBox .topBox .t_one_box .t_one_box_B_box .t_one_box_R {
  max-width: 28%;
  flex: 28%;
  height: 100%;
  box-sizing: border-box;
}

.bigBox .topBox .t_two_box {
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  flex: 32.8%;
  max-width: 32.8%;
  height: 100%;
  background: url(../../assets/detail/sbgc10.png) no-repeat;
  background-size: 100% 100%;
}

.bigBox .topBox .t_two_box .t_two_box_B_box {
  padding: 0 10%;
  box-sizing: border-box;
  display: flex;
  //flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.bigBox .topBox .t_two_box .t_two_box_B_box .t_t_b_L {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.bigBox .topBox .t_two_box .t_two_box_B_box .t_t_b_R {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.bigBox .topBox .t_three_box {
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  flex: 32.8%;
  max-width: 32.8%;
  height: 100%;
  background: url(../../assets/detail/sbgc10.png) no-repeat;
  background-size: 100% 100%;
}

.bigBox .centerBox {
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  height: 300px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.bigBox .centerBox .c_one_box {
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  flex: 24.4%;
  max-width: 24.4%;
  height: 100%;
  background: url(../../assets/detail/sbgc9.png) no-repeat;
  background-size: 100% 100%;
}

.bigBox .centerBox .c_one_box .c_one_b_box {
  height: 80%;
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.bigBox .centerBox .c_one_box .c_one_b_box .c_one_b_box_L {
  flex: 49%;
  max-width: 49%;
  height: 100%;
}

.bigBox .centerBox .c_one_box .c_one_b_box .c_one_b_box_R {
  flex: 49%;
  max-width: 49%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bigBox .centerBox .c_one_box .c_one_b_box .c_one_b_box_R .s_legnedBox {
  flex: 1;
  height: 80%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}

.bigBox .centerBox .c_one_box .c_one_b_box .c_one_b_box_R .s_legnedBox .bgcBox {
  box-sizing: border-box;
  padding: 0 10%;
  width: 100%;
  flex: 48%;
  max-height: 48%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  background: url(../../assets/detail/sbgc3.png) no-repeat;
  background-size: 100% 100%;
}

.bigBox .centerBox .c_one_box .c_one_b_box .c_one_b_box_R .s_legnedBox .bgcBox .label {
  font-size: 0.14rem;
}

.bigBox .centerBox .c_one_box .c_one_b_box .c_one_b_box_R .s_legnedBox .bgcBox .value {
  font-size: 0.18rem;
}

.bigBox .centerBox .c_two_box {
  flex: 24.4%;
  max-width: 24.4%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  background: url(../../assets/detail/sbgc9.png) no-repeat;
  background-size: 100% 100%;
}

.bigBox .centerBox .c_two_box .c_two_b_box {
  height: 80%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.bigBox .centerBox .c_two_box .c_two_b_box .c_t_box_L {
  flex: 49%;
  max-width: 49%;
  height: 100%;
}

.bigBox .centerBox .c_two_box .c_two_b_box .c_t_box_R {
  flex: 49%;
  max-width: 49%;
  height: 100%;
}

.bigBox .centerBox .c_three_box {
  flex: 24.4%;
  max-width: 24.4%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  background: url(../../assets/detail/sbgc9.png) no-repeat;
  background-size: 100% 100%;
}

.bigBox .centerBox .c_three_box .c_t_t_b_box {
  height: 80%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.bigBox .centerBox .c_three_box .c_t_t_b_box .c_t_t_b_L {
  flex: 49%;
  max-width: 49%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.bigBox .centerBox .c_three_box .c_t_t_b_box .c_t_t_b_R {
  flex: 49%;
  max-width: 49%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.bigBox .centerBox .c_four_box {
  flex: 24.4%;
  max-width: 24.4%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  background: url(../../assets/detail/sbgc9.png) no-repeat;
  background-size: 100% 100%;
}

.bigBox .centerBox .c_four_box .c_f_b_box {
  height: 42%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.bigBox .centerBox .c_four_box .c_f_b_box .deviceBgc {
  height: 80%;
  flex: 49%;
  max-width: 49%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../../assets/detail/sbgc7.png) no-repeat;
  background-size: 100% 100%;
}

.bigBox .centerBox .c_four_box .c_f_b_box .deviceBgc .imgBox {
  width: 20%;
}

.bigBox .centerBox .c_four_box .c_f_b_box .deviceBgc .contentBox {
  width: 60%;
  max-width: 60%;
  margin-left: 0.1rem;
  height: 50%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}

.bigBox .centerBox .c_four_box .c_f_b_box .deviceBgc .contentBox .labelBox {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.bigBox .centerBox .c_four_box .c_f_b_box .deviceBgc .contentBox .labelBox .labelName {
  font-size: 0.12rem;
}

.bigBox .centerBox .c_four_box .c_f_b_box .deviceBgc .contentBox .labelBox .label {
  font-size: 0.12rem;
}

.bigBox .centerBox .c_four_box .c_f_b_box .deviceBgc .contentBox .valueBox {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
}

.bigBox .centerBox .c_four_box .c_f_b_box .deviceBgc .contentBox .valueBox .valueName {
  font-size: 0.12rem;
  margin-right: 0.1rem;
}

.bigBox .centerBox .c_four_box .c_f_b_box .deviceBgc .contentBox .valueBox .value {
  font-size: 0.12rem;
}

.bigBox .bottomBox {
  box-sizing: border-box;
  width: 100%;
  margin-top: 20px;
  height: 600px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.bigBox .bottomBox .b_one_box {
  flex: 49.6%;
  max-width: 49.6%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  background: url(../../assets/detail/sbgc8.png) no-repeat;
  background-size: 100% 100%;
}

.bigBox .bottomBox .b_two_box {
  flex: 49.6%;
  max-width: 49.6%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  background: url(../../assets/detail/sbgc8.png) no-repeat;
  background-size: 100% 100%;
}

.bottomBox .sTitleBox {
  height: 10%;
}

.sTitleBox {
  height: 20%;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.16rem;
}

.titleBox {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10%;
}

.titleBox .tabBox {
  height: 100%;
  width: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleBox .tabBox .tabItem {
  height: 50%;
  flex: 49%;
  max-width: 49%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.16rem;
  cursor: pointer;
  background: #06225a;
}

.titleBox .tabBox .tabItemClick {
  height: 50%;
  flex: 49%;
  max-width: 49%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.16rem;
  background: #0e49bb;
  cursor: pointer;
}

.oneBox {
  width: 80%;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;
}

.oneBox .c_box {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
}

.oneBox .c_box .label {
  font-size: 0.12rem;
}

.oneBox .c_box .value {
  font-size: 0.18rem;
}

.legnedBox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}

.legnedBox .colorBox {
  flex: 20%;
  max-height: 20%;
  width: 100%;
  box-sizing: border-box;
  padding: 2% 10%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  background: url(../../assets/detail/sbgc3.png) no-repeat;
  background-size: 100% 100%;
}

.legnedBox .colorBox .color {
  width: 50%;
  flex: 50%;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
}

.legnedBox .colorBox .color .c {
  width: 0.1rem;
  height: 0.1rem;
  margin-right: 0.1rem;
}

.legnedBox .colorBox .color .c_label {
  font-size: 0.12rem;
}

.ttBox {
  height: 12%;
  margin-bottom: 4%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.ttBox .tt_bgc {
  height: 100%;
  flex: 48%;
  max-width: 48%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  background: url(../../assets/detail/sbgc16.png) no-repeat;
  background-size: 100% 100%;
}

.ttBox .tt_bgc .tt_bgc_L {
  display: flex;
  flex-flow: wrap;
  align-items: center;
}

.jfpg_box {
  width: 24%;
  position: absolute;
  left: 12%;
  top: 0;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.jfpg_box .colorBox {
  flex: 24%;
  max-width: 24%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.jfpg_box .colorBox .color {
  width: 0.2rem;
  height: 0.1rem;
  margin-right: 0.1rem;
}
</style>
