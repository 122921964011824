// 综合监控
import { getRequest, postRequest,monitorGateway,urlTitle,gateway} from "@/utils/request";
import qs from "qs";
window.qs = qs;
// 荷详情
export const lotusApi = {
    countByAlarmStatus :(params)=>{
        return getRequest(`${urlTitle}/loadEquipments/stat/countByAlarmStatus`,params)
    },
    processingRate :(params)=>{
        return getRequest(`${urlTitle}/loadEquipments/alarms/stat/processingRate`,params)
    },
    toplist :(params)=>{
        return getRequest(`${urlTitle}/loadEquipments/alarms/toplist`,params)
    },
    totalWithQuota :(params)=>{
        return getRequest(`${urlTitle}/loadEquipments/powerStat/today/totalWithQuota`,params)
    },
    byType :(params)=>{
        return getRequest(`${urlTitle}/loadEquipments/powerStat/today/total/byType`,params)
    },

    byStationId :(params)=>{
        return getRequest(`${urlTitle}/companies/settings/byStationId`,params)
    },
    countImportants :(params)=>{
        return getRequest(`${urlTitle}/loadEquipments/stat/countImportants`,params)
    },
    listAndTypeTotal :(params)=>{
        return getRequest(`${urlTitle}/loadEquipments/subsystems/stat/listAndTypeTotal`,params)
    },
    //今日用能
    byUsageType :(params)=>{
        return getRequest(`${urlTitle}/loadEquipments/powerStat/today/hourly/byUsageType`,params)
    },
};